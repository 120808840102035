<template>
  <div class="header-mobile">
    <div class="header-mobile__container filter">
      <div class="header-mobile__header">
        <img class="logo" src="../assets/website-logo.png" alt="" />
        <i
          class="el-icon-s-operation"
          v-if="!menuVisible"
          @click="toggleMenu"
        ></i>
        <i class="el-icon-close" v-if="menuVisible" @click="toggleMenu"></i>
      </div>
      <div
        class="header-mobile__control"
        :class="{ visible: menuVisible }"
        :style="{
          height: menuVisible ? menuList.length * 48 + 24 + 'px' : '0',
        }"
      >
        <div class="header-mobile__list">
          <router-link
            v-for="(v, k) in menuList"
            :key="k"
            class="header-mobile__item"
            active-class="active"
            :to="v.to"
            exact
          >
            {{ v.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-mobile",
  props: {},
  data() {
    return {
      menuVisible: false,
      menuActive: "",
      menuList: [
        {
          name: "首页",
          to: "/",
          value: "",
        },
        {
          name: "产品介绍",
          to: "#product",
          value: "product",
        },
        {
          name: "联系我们",
          to: "#contact",
          value: "contact",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuVisible = false;
      this.menuActive = window.location.hash.substring(1);
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .header-mobile {
    display: block !important;
  }
}

.header-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;

  .header-mobile__container {
    &:before {
      filter: blur(1px);
      background: rgba(71, 95, 117, 0.65);
      backdrop-filter: blur(3px);
    }
  }

  .header-mobile__header {
    padding: 5px 16px 10px;
    background-color: rgb(0 0 0 / 85%);
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 24px;
  }

  .logo {
    height: 100%;
  }

  .header-mobile__control {
    height: 0;
    transition: height 1.2s;
    overflow: hidden;

    &.visible {
      height: 205px;
    }
  }

  .header-mobile__list {
    text-align: left;
    padding: 24px 12px;
    margin-top: -10px;

    .header-mobile__item {
      font-size: 16px;
      padding: 12px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
      display: block;
    }

    .header-mobile__item__sub {
      font-size: 14px;
    }

    .active {
      background-color: rgba(204, 204, 204, 0.5);
      border-radius: 10px;
    }
  }
}
</style>
