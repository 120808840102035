import { render, staticRenderFns } from "./company-info.vue?vue&type=template&id=cfe40b1c&scoped=true"
import script from "./company-info.vue?vue&type=script&lang=js"
export * from "./company-info.vue?vue&type=script&lang=js"
import style0 from "./company-info.vue?vue&type=style&index=0&id=cfe40b1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfe40b1c",
  null
  
)

export default component.exports