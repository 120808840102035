<template>
  <div
    class="footer-mobile filter"
    :style="{ backgroundColor: backgroundColor, position: position }"
  >
    <div class="footer-container">
      <div class="footer-copyright">
        <div class="footer-copyright-item">
          Copyright©2024 点点文创 版权所有
        </div>
        <div
          class="footer-copyright-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          增值电信业务经营许可证：京B2-20241763
        </div>
        <div
          class="footer-copyright-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          京ICP备2023002608号-1
        </div>
        <div class="footer-copyright-item flex" style="margin-top: 8px">
          <span class="href" @click="onHref('https://jbts.mct.gov.cn/')"
            >12318全国文化市场举报</span
          >
          <span>|</span>
          <span
            class="href"
            @click="onHref('https://www.bjjubao.org.cn/index.html')"
            >网上有害信息举报</span
          >
          <span>|</span>
          <span class="href" @click="onHref('https://www.12377.cn/')"
            >违法和不良信息举报中心</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-mobile",
  props: {
    backgroundColor: {
      type: String,
      default: "#1A2638",
    },
    position: {
      type: String,
      default: "relative",
    },
  },
  methods: {
    onHref(url) {
      if (url) window.open(url);
    },
  },
};
</script>

<style scoped>
.footer-mobile {
  position: relative;
  padding: 25px 16px;
  width: 100%;
  bottom: 0;
  border: none;

  &:before {
    filter: blur(1px);
    background: rgb(71 95 117 / 15%);
    backdrop-filter: blur(1px);
  }

  .footer-container {
    width: 100%;
    position: relative;
  }

  .footer-copyright {
    margin-top: 8px;
    padding: 0 20px;

    .href {
      &:hover {
        text-decoration: underline;
      }
    }

    .footer-copyright-item {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 1.3;
    }

    .flex {
      > span {
        padding: 0 5px;
      }
    }
  }
}
</style>
