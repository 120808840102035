<template>
  <div class="page-home">
    <div class="page-main">
      <div class="page-container">
        <div id="company" class="company-info">
          <div class="company-info__layout">
            <company-info></company-info>
          </div>
        </div>
        <div id="product" class="product">
          <div class="product-info">
            <div
              class="product-info__box filter"
              @click="onHref('//telsha.net')"
            >
              <img
                class="product-info__logo"
                src="../../assets/website-product-logo-01.png"
                alt=""
              />
              <div class="product-info__title">应用名称：粟鼠旅行</div>
              <div class="product-info__version">
                当前版本：测试中（敬请期待）
              </div>
              <div class="product-info__content">
                粟鼠旅行是一款娱乐化工具软件，为用户提供游戏化电子日记和轻社交功能，致力于打造云端的精神家园。
              </div>
            </div>
          </div>
          <div class="product-info">
            <div class="product-info__box filter" @click="onHref('')">
              <img
                class="product-info__logo"
                src="../../assets/website-product-logo-02.png"
                alt=""
              />
              <div class="product-info__title">应用名称：树屋日记</div>
              <div class="product-info__version">
                当前版本：测试中（敬请期待）
              </div>
              <div class="product-info__content">
                树屋日记是一款记录心流笔记的移动端app软件，为用户提供简洁高效的情绪管理工具，帮助用户发展正念、逐步提升精神能量。
              </div>
            </div>
          </div>
        </div>
        <div id="contact" class="contact-us">
          <img
            class="contact-us__img"
            src="../../assets/website-logo-02.png"
            alt=""
          />
          <div class="contact-us__title">联系我们</div>
          <div class="contact-us__content">
            <div class="contact-us__item">
              <img
                class="contact-us__icon"
                src="../../assets/website-about-icon-01.svg"
                alt=""
              />
              <div class="contact-us__item-title">业务合作：</div>
              <div class="contact-us__item-content">operate@pointscc.com</div>
            </div>
            <div class="contact-us__item">
              <img
                class="contact-us__icon"
                src="../../assets/website-about-icon-02.svg"
                alt=""
              />
              <div class="contact-us__item-title">加入我们：</div>
              <div class="contact-us__item-content">hr@pointscc.com</div>
            </div>
            <div class="contact-us__item">
              <img
                class="contact-us__icon"
                src="../../assets/website-about-icon-03.svg"
                alt=""
              />
              <div class="contact-us__item-title">公司地址：</div>
              <div class="contact-us__item-content">
                北京市朝阳区青年路西里3号院7号楼1层
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-pc background-color="#000"></footer-pc>
    </div>

    <div class="page-container-mobile">
      <div class="company-info-mobile" v-show="menuActive === ''">
        <company-info></company-info>
      </div>

      <div class="product-info-mobile" v-show="menuActive === 'product'">
        <div class="product-info-mobile__list">
          <div
            class="product-info-mobile__item"
            v-for="(v, k) in productList"
            :key="k"
            :class="{ active: k === productActive }"
            @click="onSelectProduct(v, k)"
          >
            <img class="product-info-mobile__cg" :src="v.cg" alt="" />
            <div
              class="product-info-mobile__card"
              :style="{
                background: `url(${v.bg}) left top / 100% 65% no-repeat,
                url(${v.fg}) left bottom / 100% 35% no-repeat`,
              }"
            >
              <div class="flex">
                <div class="product-info-mobile__logo">
                  <img class="product-info-mobile__logo" :src="v.logo" alt="" />
                  <div class="product-info-mobile__logo-title">
                    {{ v.name }}
                  </div>
                </div>
                <div class="product-info-mobile__desc">
                  <div class="product-info-mobile__content">
                    {{ v.desc }}
                  </div>
                  <div class="product-info-mobile__version">
                    {{ v.version }}
                  </div>
                </div>
              </div>
              <img
                class="product-info-mobile__download"
                src="../../assets/mobile/home-m-product-download.png"
                alt=""
                @click.stop="onDownload(v)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="contact-us-mobile-layout" v-show="menuActive === 'contact'">
        <div class="contact-us-mobile">
          <span class="yt"></span>
          <span class="yl"></span>
          <span class="brb"></span>
          <div class="contact-us-mobile__title">联系我们</div>
          <div class="contact-us-mobile__content">
            <div class="contact-us-mobile__item">
              <img
                class="contact-us-mobile__icon"
                src="../../assets/website-about-icon-01.svg"
                alt=""
              />
              <div class="contact-us-mobile__item-title">业务合作：</div>
              <div class="contact-us-mobile__item-content">
                operate@pointscc.com
              </div>
            </div>
            <div class="contact-us-mobile__item">
              <img
                class="contact-us-mobile__icon"
                src="../../assets/website-about-icon-02.svg"
                alt=""
              />
              <div class="contact-us-mobile__item-title">加入我们：</div>
              <div class="contact-us-mobile__item-content">hr@pointscc.com</div>
            </div>
            <div class="contact-us-mobile__item">
              <img
                class="contact-us-mobile__icon"
                src="../../assets/website-about-icon-03.svg"
                alt=""
              />
              <div class="contact-us-mobile__item-title">公司地址：</div>
              <div class="contact-us-mobile__item-content">
                北京市朝阳区青年路西里3号院7号楼1层
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-mobile-box">
        <footer-mobile
          :position="'fixed'"
          background-color="transparent"
        ></footer-mobile>
      </div>
    </div>
  </div>
</template>

<script>
import FooterPc from "@/components/footerPc.vue";
import FooterMobile from "@/components/footerMobile.vue";
import CompanyInfo from "@/components/company-info.vue";

export default {
  name: "homeIndex",
  components: { CompanyInfo, FooterMobile, FooterPc },
  data() {
    return {
      menuActive: "",
      productActive: 0,
      productList: [
        {
          id: 1,
          name: "粟鼠旅行",
          desc: "粟鼠旅行是一款娱乐化工具软件，为用户提供游戏化电子日记和轻社交功能，致力于打造云端的精神家园。",
          version: "测试中（敬请期待）",
          logo: require("../../assets/website-product-logo-01.png"),
          bg: require("../../assets/mobile/home-m-product-01-bg.png"),
          fg: require("../../assets/mobile/home-m-product-01-fg.png"),
          cg: require("../../assets/mobile/home-m-product-01-cg.png"),
          url: "//telsha.net",
        },
        {
          id: 2,
          name: "树屋日记",
          desc: "树屋日记是一款记录心流笔记的移动端app软件，为用户提供简洁高效的情绪管理工具，帮助用户发展正念、逐步提升精神能量。",
          version: "测试中（敬请期待）",
          logo: require("../../assets/website-product-logo-02.png"),
          bg: require("../../assets/mobile/home-m-product-02-bg.png"),
          fg: require("../../assets/mobile/home-m-product-02-fg.png"),
          cg: require("../../assets/mobile/home-m-product-02-cg.png"),
          url: "",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = window.location.hash.substring(1);
      console.log(this.menuActive);
    },
  },
  mounted() {
    this.menuActive = window.location.hash.substring(1);
  },
  methods: {
    onSelectProduct(v, k) {
      let temp = k + 1;
      if (temp > this.productList.length - 1) temp = 0;
      this.productActive = temp;
    },
    onDownload(v) {
      if (v.url) window.open(v.url);
    },
    onHref(url) {
      if (url) window.open(url);
    },
  },
};
</script>

<style scoped>
.page-home {
  .page-main {
    width: 1440px;
    height: 1080px;
    margin: 0 auto;
    color: #fff;
    background: url("../../assets/website-bg.png") center top no-repeat;
    background-size: 100%;
  }

  .page-container {
    width: 1200px;
    margin: 0 auto;
    padding: 88px 0 120px;
  }

  .company-info {
    padding-top: 40px;
    height: calc(790px - 88px);

    .company-info__layout {
      width: 426px;
      height: 321px;
      top: 125px;
      position: relative;
    }
  }

  .product {
    margin-top: -30px;
  }

  .product-info {
    position: relative;
    height: 600px;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 30px;
    background: url("../../assets/website-product-bg-01.png") center top
      no-repeat;
    background-size: 100%;

    &:nth-child(2n) {
      background: url("../../assets/website-product-bg-02.png") center top
        no-repeat;
      background-size: 100%;

      .product-info__box {
        right: 0;
        left: auto;

        &:before {
          background: url("../../assets/website-product-bg-02.png") right top
            no-repeat;
          background-size: 1200px 600px;
        }
      }
    }

    .product-info__box {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 488px;
      padding: 0 46px;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;

      &:before {
        background: url("../../assets/website-product-bg-01.png") left top
          no-repeat;
        background-size: 1200px 600px;
      }

      .product-info__logo {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        margin-top: 40%;
        margin-bottom: 20px;
      }

      .product-info__title {
        font-size: 24px;
        margin-bottom: 5px;
      }

      .product-info__version {
        font-size: 16px;
        color: #999;
        margin-bottom: 20px;
      }

      .product-info__content {
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }

  .contact-us {
    display: flex;
    color: #333;
    gap: 32px;
    padding-top: 94px;
    position: relative;
    margin-bottom: 90px;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #ddd;
      top: 64px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      background: #ddd;
      top: 30px;
      bottom: 0;
      left: 260px;
    }

    .contact-us__img {
      width: 180px;
      flex: 0.2;
      object-fit: contain;
    }

    .contact-us__title {
      flex: 0.4;
      font-size: 32px;
      font-weight: 600;
      position: relative;
      text-align: right;
      padding-right: 63px;

      &:before {
        position: absolute;
        content: "‘‘";
        color: #ddd;
        font-size: 128px;
        right: 210px;
        top: -25px;
      }

      &:after {
        position: absolute;
        content: "";
        background: #ddd;
        width: 4px;
        height: 124px;
        right: 0;
      }
    }

    .contact-us__content {
      flex: 0.4;
      text-align: left;
      font-size: 18px;
    }

    .contact-us__item {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-bottom: 25px;
    }

    .contact-us__icon {
      width: 18.8px;
      height: 18.8px;
      margin-right: 25px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 500px) {
    .page-main {
      display: none;
    }

    .page-container-mobile {
      display: block !important;
    }

    .company-info-mobile {
      padding: 10px;
      position: absolute;
      bottom: 210px;
    }

    .product-info-mobile {
      padding: 30px;
      position: absolute;
      bottom: 150px;
      color: #fff;
      left: 0;
      right: 0;
      height: 67vh;

      .product-info-mobile__list {
        position: relative;
      }

      .product-info-mobile__item {
        position: absolute;
        transition: 1s;
        opacity: 0;
        z-index: 8;

        &.active {
          transform: rotateY(360deg);
          opacity: 1;
          z-index: 10;
        }

        .product-info-mobile__cg {
          position: absolute;
          left: 20px;
          top: -20px;
          width: 100%;
          z-index: 1;
        }

        .product-info-mobile__card {
          position: relative;
          z-index: 11;
          padding: 30px 30px 200px;
          //background: url("../../assets/mobile/home-m-product-01-bg.png") left
          //    top / 100% 60% no-repeat,
          //  url("../../assets/mobile/home-m-product-01-fg.png") left bottom /
          //    100% 40% no-repeat;
        }

        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
        }
      }

      .product-info-mobile__logo-title {
        font-size: 26px;
        font-weight: bold;
        margin-top: 15px;
      }

      .product-info-mobile__content {
        text-align: left;
        line-height: 2;
        text-indent: 1em;
        font-size: 14px;
        opacity: 0.9;
      }

      .product-info-mobile__version {
        opacity: 0.7;
        margin-top: 5px;
        font-size: 14px;
      }

      .product-info-mobile__download {
        margin-top: 15px;
        width: 180px;

        &:active {
          opacity: 0.8;
        }
      }
    }

    .contact-us-mobile-layout {
      padding: 27px;
      position: absolute;
      bottom: 210px;
      width: 100%;

      .contact-us-mobile {
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        padding: 57px 30px 104px;
        text-align: left;
        position: relative;

        .yt {
          width: 85px;
          height: 20px;
          background-color: #e7ba5a;
          display: inline-block;
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .yl {
          width: 20px;
          height: 72px;
          background-color: #e7ba5a;
          display: inline-block;
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .brb {
          width: 29px;
          height: 27px;
          background-color: #000;
          display: inline-block;
          border-radius: 5px 0 0 0;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .contact-us-mobile__title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 38px;
        color: #000;
        position: relative;
        padding-left: 2em;

        &:before {
          position: absolute;
          content: "‘‘";
          color: #ddd;
          font-size: 80px;
          left: 0;
          top: -25px;
        }
      }

      .contact-us-mobile__content {
        flex: 0.4;
        text-align: left;
        font-size: 13px;
        color: #333;
        position: relative;
        padding-left: 10px;

        &:before {
          content: "";
          width: 4px;
          background-color: #000;
          display: inline-block;
          position: absolute;
          left: 0;
          top: -10px;
          bottom: -10px;
          opacity: 0.1;
        }
      }

      .contact-us-mobile__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 25px;
        line-height: 1.6;
        font-weight: bold;
      }

      .contact-us-mobile__item-title {
        white-space: nowrap;
      }

      .contact-us-mobile__icon {
        width: 18.8px;
        height: 18.8px;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }

  .page-container-mobile {
    position: relative;
    height: 100vh;
    background: url("../../assets/mobile/home-m-bg.png") bottom center no-repeat;
    background-size: cover;
    display: none;
  }
}
</style>
