<template>
  <div class="footer" :style="{ backgroundColor: backgroundColor }">
    <div class="footer-container">
      <div class="footer-left">
        <div class="footer-left-item">Copyright©2024 点点文创 版权所有</div>
        <div
          class="footer-left-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          增值电信业务经营许可证：京B2-20241763 | 京ICP备2023002608号-1
        </div>
        <div class="footer-left-item">
          <span class="href" @click="onHref('https://jbts.mct.gov.cn/')"
            >12318全国文化市场举报</span
          >
          <span>|</span>
          <span
            class="href"
            @click="onHref('https://www.bjjubao.org.cn/index.html')"
            >网上有害信息举报</span
          >
          <span>|</span>
          <span class="href" @click="onHref('https://www.12377.cn/')"
            >违法和不良信息举报中心</span
          >
        </div>
      </div>
      <div class="footer-nav">
        <div class="footer-nav-item">关于我们</div>
        |
        <div class="footer-nav-item">联系我们</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-pc",
  props: {
    msg: String,
    backgroundColor: {
      type: String,
      default: "#1A2638",
    },
  },
  methods: {
    onHref(url) {
      if (url) window.open(url);
    },
  },
};
</script>

<style scoped>
.footer {
  padding: 40px 120px 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;

  .footer-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer-left {
    text-align: left;

    .href {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .footer-left-item {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 2;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .footer-nav {
    display: flex;
    align-items: center;
    gap: 10px;

    .footer-nav-item {
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
