<template>
  <div id="app">
    <header-pc></header-pc>
    <header-mobile></header-mobile>
    <router-view />
  </div>
</template>

<script>
import HeaderPc from "@/components/headerPc.vue";
import HeaderMobile from "@/components/headerMobile.vue";

export default {
  components: {
    HeaderMobile,
    HeaderPc,
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

a,
img {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.filter {
  position: relative;
  border: 1px solid rgba(152, 148, 148, 0.4);

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(10px);
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  * {
    position: relative;
    z-index: 11;
  }
}
</style>
