<template>
  <div>
    <div class="company-info__box filter">
      <div class="company-info__title">
        <img src="../assets/website-company-title.svg" alt="" />
        <span class="line"></span>
        <span class="arrow">
          <i class="el-icon-arrow-right"></i>
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="company-info__content">
        点点文创是一家拥有社会责任的跨界创新型创业公司，致力于用建构主义方法论探索当代人文发展的有效路径。创始团队拥有文化、教育、互联网、金融等多元化经验和背景。正在孵化的产品以心灵治愈系为主，包括工具软件、游戏、动漫、文化衍生品等综合体系。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "company-info",
};
</script>

<style scoped>
.company-info__box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 30px 25px;
  text-align: left;
  overflow: hidden;
  color: #fff;

  &:before {
    filter: blur(5px);
    background: rgb(71 95 117 / 15%);
    backdrop-filter: blur(1px);
  }

  .company-info__title {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 14px;

    .line {
      height: 1px;
      background-color: #fff;
      opacity: 0.4;
      flex: 1;
      position: relative;
      top: -2px;
    }

    .arrow {
      margin-bottom: -5px;
      opacity: 0.4;
    }

    .el-icon-arrow-right {
      font-size: 10px;
    }
  }

  .company-info__content {
    font-size: 18px;
    line-height: 2;
    text-indent: 2em;
  }
}
</style>
