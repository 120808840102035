<template>
  <div class="header-pc">
    <div class="header-pc-container">
      <img class="logo" src="../assets/website-logo.png" alt="" />
      <div class="header-pc-list">
        <router-link
          v-for="(v, k) in menuList"
          :key="k"
          class="header-pc__item"
          :class="{ active: menuActive === v.value }"
          :to="v.to"
        >
          <span>{{ v.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-pc",
  props: {
    msg: String,
  },
  data() {
    return {
      menuActive: "",
      menuList: [
        {
          name: "首页",
          to: "/",
          value: "",
        },
        {
          name: "产品介绍",
          to: "#product",
          value: "product",
        },
        {
          name: "联系我们",
          to: "#contact",
          value: "contact",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.scrollToAnchor();
    },
  },
  mounted() {
    this.scrollToAnchor();
  },
  beforeDestroy() {},
  methods: {
    scrollToAnchor() {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          this.smoothScrollTo(element.offsetTop - 88);
        }
      } else {
        this.smoothScrollTo(0);
      }
      this.menuActive = hash;
    },
    smoothScrollTo(position) {
      const start = window.pageYOffset;
      const distance = position - start;
      const duration = 500;

      let startTime = null;

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeInOutQuad(timeElapsed, start, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      };

      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      requestAnimationFrame(animation);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .header-pc {
    display: none;
  }
}

.header-pc {
  padding: 0 120px;
  background-color: rgb(0 0 0 / 85%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;

  .header-pc-container {
    width: 1200px;
    margin: 0 auto;
    height: 88px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 230px;
    height: 80px;
    align-self: flex-end;
  }

  .header-pc-list {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 40px;

    .header-pc__item {
      width: 119px;
      height: 88px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.6);

      > span {
        position: relative;
        top: 40px;
      }

      &.active {
        color: #fff;
        background: url("../assets/menu-active.png") top center no-repeat,
          url("../assets/menu-active-bg.png") center center no-repeat,
          url("../assets/menu-active-text-bg.png") bottom 8px center no-repeat;

        > span {
          //box-shadow: 0 0 18px 0 #8be3abbd;
        }
      }

      &:hover {
      }
    }
  }

  .header-pc-login {
    width: 119px;

    .header-pc-login__item {
      padding: 5px 10px;
      border: 1px solid rgba(238, 238, 238, 60);
      color: rgba(255, 255, 255, 0.6);
      border-radius: 15px;
      text-decoration: none;
    }
  }
}
</style>
